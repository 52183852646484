/* eslint-disable global-require */
export const data = {
  faqData: {
    // payment
    payment: {
      icon: 'CreditCardIcon',
      title: 'Purchase',
      subtitle: 'Purchase Money Mortgage',
      qandA: [
        {
          question: 'What Is a Purchase Money Mortgage and How Does it Work?',
          ans:
            'Sometimes, a person buying real property gives the seller a mortgage on the property as part of the deal to buy the property. This is called a purchase money mortgage, because this type of mortgage usually replaces part or all of the cash that the buyer would otherwise pay the seller.',
        },
      ],
    },
    // delivery
    delivery: {
      icon: 'ShoppingBagIcon',
      title: 'Refinance',
      subtitle: 'Mortgage Refinancing',
      qandA: [
        {
          question: 'What is refinancing?',
          ans: 'When you refinance your mortgage, you replace your current mortgage with a new loan. The new loan might have different terms — moving from a 30-year to a 15-year term or an adjustable rate to a fixed rate, for example — but the most common change is a lower interest rate. Refinancing can allow you to lower your monthly payment, save money on interest over the life of your loan, pay your mortgage off sooner and draw from your home’s equity if you need cash for any purpose.',
        },
      ],
    },
    // cancellation and return
    cancellationReturn: {
      icon: 'RefreshCwIcon',
      title: 'Renovation',
      subtitle: 'Renovation Mortgage Loan',
      qandA: [
        {
          question: 'What Is A Renovation Mortgage Loan?',
          ans:
            'A renovation mortgage loan allows borrowers to buy the home they want and pay for their desired renovations and repairs all under a single loan. The loan can then be paid back over time through affordable monthly payments, just like with a conventional 30-or-15-year mortgage.',
        },
      ],
    },
  },
}
