<template>
  <div>
    <navbar />
    <bread-creamb title="FAQ's" />
    <section class="blog-view">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <!-- blog content view start -->
            <div class="blog-content-view">
              <app-collapse accordion>
                <app-collapse-item
                  v-for="(faq,index) in faqs"
                  :key="index"
                  :title="faq.question"
                >
                {{ faq.answer }}
                </app-collapse-item>
              </app-collapse>
            </div>
            <!-- blog content view end -->
          </div>
        </div>
      </div>
    </section>
    <!-- <blog />
    <subscribe /> -->
    <app-footer />
  </div>

</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppFooter from './site-content/AppFooter.vue'
import Blog from './site-content/Blog.vue'
import Navbar from './site-content/Navbar.vue'
import Subscribe from './site-content/Subscribe.vue'
import BreadCreamb from './site-pages/BreadCreamb.vue'
import { data } from './faq-data'

export default {
  components: {
    Navbar,
    BreadCreamb,
    AppFooter,
    Subscribe,
    Blog,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      faqData: data.faqData,
    }
  },
  computed: {
    faqs() {
      return this.$store.getters['auth/faqs']
    },
  },
}
</script>

<style>

</style>
